export default {
  Partner: {
    fields: {
      quizzes: { merge: false },
      channels: { merge: false },
      pricing: { merge: false },
    },
  },
  PartnerSettings: {
    fields: {
      bills: { merge: false },
    },
  },
};
