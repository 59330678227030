import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';

const Redirect = function () {
  const { partnerId, expertId } = useParams();
  const { pathname } = useLocation();

  let path = `${process.env.COMMUNITY_ENDPOINT}/${partnerId}`;

  if (expertId) {
    path += `/guurus/${expertId}`;
  } else if (pathname.includes('community')) {
    path += '/home';
  } else if (pathname.includes('invitation')) {
    path += '/invitation';
  } else {
    path += '/chats';
  }

  useEffect(() => {
    window.location.replace(path);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default Redirect;
