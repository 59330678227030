import cloneDeep from 'lodash/cloneDeep';
import lremove from 'lodash/remove';

const addResult = ({
  list = (value) => value,
  element = (value) => value,
  updateQuery = () => {},
  removeBeforeAdd,
} = {}) => (...arg) => {
  updateQuery((previous) => {
    const result = element(...arg);
    if (!result) return { ...previous };
    const newResult = cloneDeep(previous);
    if (removeBeforeAdd) {
      lremove(list(newResult), removeBeforeAdd);
    }
    list(newResult).unshift(result);
    return newResult;
  });
};

const removeResult = ({
  elementId = (value) => value,
  list = (value) => value,
  getId = (value) => value,
  updateQuery = () => {},
} = {}) => (...result) => {
  updateQuery((previous) => {
    const resultId = elementId(...result);
    if (!resultId) return { ...previous };
    const newResult = cloneDeep(previous);
    lremove(list(newResult), (elem) => getId(elem) === resultId);
    return newResult;
  });
};

const addToCache = (cache, {
  field,
  node,
  id = 'ROOT_QUERY',
}) => (
  cache.modify({
    id,
    fields: {
      [field](existing = { edges: [] }) {
        return {
          ...existing,
          edges: [
            // eslint-disable-next-line no-underscore-dangle
            { node: { ...node, __ref: `${node.__typename}:${node.id}` } },
            ...existing.edges,
          ],
        };
      },
    },
  })
);

const removeFromCache = (cache, {
  field,
  node,
  id = 'ROOT_QUERY',
}) => (
  cache.modify({
    id,
    fields: {
      [field](existing = { edges: [] }) {
        return {
          ...existing,
          edges: existing.edges.filter(
            // eslint-disable-next-line no-underscore-dangle
            (edge) => edge.node.__ref !== `${node.__typename}:${node.id}`,
          ),
        };
      },
    },
  })
);

export {
  addResult,
  addToCache,
  removeResult,
  removeFromCache,
};
