import React, { Suspense } from 'react';
import ThemeProvider from '@guuru/theme-web';
import {
  Col, Flex, Layout, Row, theme,
} from 'antd';
import { colors } from '@guuru/constants-common';
import { PartnerLogo } from '@guuru/react-partner-logo';
import { Navigate, Outlet } from 'react-router-dom';
import { Loading } from '@guuru/react-loading';
import PropTypes from 'prop-types';
import { PoweredBy } from '@guuru/react-powered-by';
import { useCommunitySettings } from '@guuru/community-web';

const { Header, Content, Footer } = Layout;

const { useToken } = theme;

const themeConfig = {
  components: {
    Card: {
      headerHeightSM: 20,
    },
    Rate: {
      marginXS: 4,
      controlHeightLG: 30,
    },
    Layout: {
      bodyBg: '#ffffff',
      headerBg: '#ffffff',
      siderBg: '#ffffff',
      footerBg: '#ffffff',
      headerPadding: 0,
    },
  },
  token: {
    paddingLG: '12px',
  },
};

const BasicLayout = function ({ header = null }) {
  const { token } = useToken();
  const { partnerName, partnerId } = useCommunitySettings();

  if (!partnerName) {
    return <Navigate to="/" />;
  }

  return (
    <ThemeProvider
      theme={{ color: colors.primaryColor, antd: token }}
      configProvider={themeConfig}
    >
      <Layout>
        {header && (
          <Header>
            {header()}
          </Header>
        )}
        <Content>
          <Flex vertical gap={38}>
            <Col />
            <PartnerLogo id={partnerId} />
            <Row align="center">
              <Col xs={22} xxl={20}>
                <Row align="center">
                  <Suspense fallback={<Loading height={100} />}>
                    <Outlet />
                  </Suspense>
                </Row>
              </Col>
            </Row>
            <Col />
          </Flex>
        </Content>
        <Footer style={{ textAlign: 'center' }}>
          <PoweredBy url={`https://www.guuru.com/?utm_source=pages&utm_medium=pages&utm_campaign=pages&utm_term=${partnerId}&utm_content=${partnerId}`} />
        </Footer>
      </Layout>
    </ThemeProvider>
  );
};

BasicLayout.propTypes = {
  header: PropTypes.func,
};

export default BasicLayout;
